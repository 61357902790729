export interface HeadMetaType {
  sitename: string;
  host: string;
  language: string;
  title: string;
  description: string;
  shareImage: string;
  appIconImage: string;
  favIconImage: string;
}

export const getDefaultHeadMetaData = (): HeadMetaType => {
  return {
    sitename: 'Sanford and Sons',
    host: process.env.NEXT_PUBLIC_HOST,
    language: 'en_NZ',
    title: 'Welcome to Next Boilerplate',
    description: 'Welcome to Next Boilerplate',
    shareImage: 'assets/share/share.jpg', // relative path based on host from env file
    appIconImage: '/assets/share/app-icon.png', // absolute path
    favIconImage: '/assets/share/fav-icon.png', // absolute path
  };
};

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
export const GOOGLE_SITE_VERIFICATION = process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID;
export const FB_SITE_VERIFICATION = process.env.NEXT_PUBLIC_FB_SITE_VERIFICATION;
export const GA4 = process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID;
